import { default as competition_45privacy_45policysWtbPyWhAEMeta } from "/home/runner/work/injective-helix/injective-helix/pages/competition-privacy-policy.vue?macro=true";
import { default as fee_45discountsNnP0BhLLmhMeta } from "/home/runner/work/injective-helix/injective-helix/pages/fee-discounts.vue?macro=true";
import { default as _91slug_938aAM41WdyeMeta } from "/home/runner/work/injective-helix/injective-helix/pages/futures/[slug].vue?macro=true";
import { default as index9NykpjxZXxMeta } from "/home/runner/work/injective-helix/injective-helix/pages/futures/index.vue?macro=true";
import { default as futuresnpHbDHdvqiMeta } from "/home/runner/work/injective-helix/injective-helix/pages/futures.vue?macro=true";
import { default as _91guild_9390ZtHiQJlDMeta } from "/home/runner/work/injective-helix/injective-helix/pages/guild/[guild].vue?macro=true";
import { default as guilds9pFpoT2EeuMeta } from "/home/runner/work/injective-helix/injective-helix/pages/guilds.vue?macro=true";
import { default as index2VwdV4NPDlMeta } from "/home/runner/work/injective-helix/injective-helix/pages/index.vue?macro=true";
import { default as institutionalcGAOunyS6jMeta } from "/home/runner/work/injective-helix/injective-helix/pages/institutional.vue?macro=true";
import { default as competitiong38OTiqeHmMeta } from "/home/runner/work/injective-helix/injective-helix/pages/leaderboard/competition.vue?macro=true";
import { default as indexWhkutWQAAGMeta } from "/home/runner/work/injective-helix/injective-helix/pages/leaderboard/index.vue?macro=true";
import { default as leaderboardIhdheiel0pMeta } from "/home/runner/work/injective-helix/injective-helix/pages/leaderboard.vue?macro=true";
import { default as like_45a_45g_45competition_45terms1r0EXala9IMeta } from "/home/runner/work/injective-helix/injective-helix/pages/like-a-g-competition-terms.vue?macro=true";
import { default as liquidity_45provisions7i2qW7hLtMeta } from "/home/runner/work/injective-helix/injective-helix/pages/liquidity-provision.vue?macro=true";
import { default as campaignLJiJgVjAmFMeta } from "/home/runner/work/injective-helix/injective-helix/pages/lp-rewards/campaign.vue?macro=true";
import { default as dashboardsoSU2pauUgMeta } from "/home/runner/work/injective-helix/injective-helix/pages/lp-rewards/dashboard.vue?macro=true";
import { default as indexuF84KHvqfxMeta } from "/home/runner/work/injective-helix/injective-helix/pages/lp-rewards/index.vue?macro=true";
import { default as lp_45rewardslLhrruBlGdMeta } from "/home/runner/work/injective-helix/injective-helix/pages/lp-rewards.vue?macro=true";
import { default as maintenanceh39gOlm6VnMeta } from "/home/runner/work/injective-helix/injective-helix/pages/maintenance.vue?macro=true";
import { default as marketsb1HdpQPHOHMeta } from "/home/runner/work/injective-helix/injective-helix/pages/markets.vue?macro=true";
import { default as playgroundangNhcvgACMeta } from "/home/runner/work/injective-helix/injective-helix/pages/playground.vue?macro=true";
import { default as indexPKbXoGXOH0Meta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/balances/index.vue?macro=true";
import { default as indexBtGrH78S2FMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/history/funding-payments/index.vue?macro=true";
import { default as indexGXnzm7sDI1Meta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/history/swap/index.vue?macro=true";
import { default as indexoy8tr6QzuEMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/history/wallet/index.vue?macro=true";
import { default as indexq4LDPBlcZiMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/index.vue?macro=true";
import { default as futures_45gridN94WgXYni7Meta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/futures-grid.vue?macro=true";
import { default as indexiyGTfAbBhYMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/futures/index.vue?macro=true";
import { default as order_45historyZiaUXk8I09Meta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/futures/order-history.vue?macro=true";
import { default as trade_45historyuMMHhPjFQUMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/futures/trade-history.vue?macro=true";
import { default as triggerskBZj5Sd8uRMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/futures/triggers.vue?macro=true";
import { default as futures9W40KNjEyEMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/futures.vue?macro=true";
import { default as history7UN89I3fzXMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/spot-grid/history.vue?macro=true";
import { default as indexfVQvUBoxhuMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/spot-grid/index.vue?macro=true";
import { default as spot_45gridc1ZB6pm3igMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/spot-grid.vue?macro=true";
import { default as indextJkZEngQ9TMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/spot/index.vue?macro=true";
import { default as order_45historytFHyhlqgpMMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/spot/order-history.vue?macro=true";
import { default as trade_45history5tVZep4F4qMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/spot/trade-history.vue?macro=true";
import { default as spotmIGxOW6cWBMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/spot.vue?macro=true";
import { default as indexARQsdlKOqDMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/positions/index.vue?macro=true";
import { default as grantereOwTYexfc7Meta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/settings/authz/granter.vue?macro=true";
import { default as indexzhbqHn8T4LMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/settings/authz/index.vue?macro=true";
import { default as authzOFwuU4U3TrMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/settings/authz.vue?macro=true";
import { default as autosignGiK89gXGNQMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/settings/autosign.vue?macro=true";
import { default as indexc8bOd7pD3dMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/settings/index.vue?macro=true";
import { default as preferencestMvkkSX2x0Meta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/settings/preferences.vue?macro=true";
import { default as indexcnvSS8QQl6Meta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/subaccounts/index.vue?macro=true";
import { default as portfolioIUJFZ6NLTnMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio.vue?macro=true";
import { default as _91slug_93kxmdUIcQ6GMeta } from "/home/runner/work/injective-helix/injective-helix/pages/spot/[slug].vue?macro=true";
import { default as indexT7HjhYUG5NMeta } from "/home/runner/work/injective-helix/injective-helix/pages/spot/index.vue?macro=true";
import { default as spotT07bwsa8pYMeta } from "/home/runner/work/injective-helix/injective-helix/pages/spot.vue?macro=true";
import { default as swapjOTFhDPqakMeta } from "/home/runner/work/injective-helix/injective-helix/pages/swap.vue?macro=true";
import { default as termsuQEhPn6W4WMeta } from "/home/runner/work/injective-helix/injective-helix/pages/terms.vue?macro=true";
import { default as trade_45and_45earndFVWQJzi6xMeta } from "/home/runner/work/injective-helix/injective-helix/pages/trade-and-earn.vue?macro=true";
import { default as historyVTkDFPJDWYMeta } from "/home/runner/work/injective-helix/injective-helix/pages/trading-bots/liquidity-bots/spot/history.vue?macro=true";
import { default as indexz0GzSFxN16Meta } from "/home/runner/work/injective-helix/injective-helix/pages/trading-bots/liquidity-bots/spot/index.vue?macro=true";
import { default as liquidity_45botskE9zy5SQHDMeta } from "/home/runner/work/injective-helix/injective-helix/pages/trading-bots/liquidity-bots.vue?macro=true";
import { default as transferILiF6UTtdtMeta } from "/home/runner/work/injective-helix/injective-helix/pages/transfer.vue?macro=true";
export default [
  {
    name: "competition-privacy-policy",
    path: "/competition-privacy-policy",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/competition-privacy-policy.vue")
  },
  {
    name: "fee-discounts",
    path: "/fee-discounts",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/fee-discounts.vue")
  },
  {
    name: futuresnpHbDHdvqiMeta?.name,
    path: "/futures",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/futures.vue"),
    children: [
  {
    name: "futures-slug",
    path: ":slug()",
    meta: _91slug_938aAM41WdyeMeta || {},
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/futures/[slug].vue")
  },
  {
    name: "futures",
    path: "",
    meta: index9NykpjxZXxMeta || {},
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/futures/index.vue")
  }
]
  },
  {
    name: "guild-guild",
    path: "/guild/:guild()",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/guild/[guild].vue")
  },
  {
    name: "guilds",
    path: "/guilds",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/guilds.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/index.vue")
  },
  {
    name: "institutional",
    path: "/institutional",
    meta: institutionalcGAOunyS6jMeta || {},
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/institutional.vue")
  },
  {
    name: leaderboardIhdheiel0pMeta?.name,
    path: "/leaderboard",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/leaderboard.vue"),
    children: [
  {
    name: "leaderboard-competition",
    path: "competition",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/leaderboard/competition.vue")
  },
  {
    name: "leaderboard",
    path: "",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/leaderboard/index.vue")
  }
]
  },
  {
    name: "like-a-g-competition-terms",
    path: "/like-a-g-competition-terms",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/like-a-g-competition-terms.vue")
  },
  {
    name: "liquidity-provision",
    path: "/liquidity-provision",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/liquidity-provision.vue")
  },
  {
    name: lp_45rewardslLhrruBlGdMeta?.name,
    path: "/lp-rewards",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/lp-rewards.vue"),
    children: [
  {
    name: "lp-rewards-campaign",
    path: "campaign",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/lp-rewards/campaign.vue")
  },
  {
    name: "lp-rewards-dashboard",
    path: "dashboard",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/lp-rewards/dashboard.vue")
  },
  {
    name: "lp-rewards",
    path: "",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/lp-rewards/index.vue")
  }
]
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenanceh39gOlm6VnMeta || {},
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/maintenance.vue")
  },
  {
    name: "markets",
    path: "/markets",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/markets.vue")
  },
  {
    name: "playground",
    path: "/playground",
    meta: playgroundangNhcvgACMeta || {},
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/playground.vue")
  },
  {
    name: portfolioIUJFZ6NLTnMeta?.name,
    path: "/portfolio",
    meta: portfolioIUJFZ6NLTnMeta || {},
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio.vue"),
    children: [
  {
    name: "portfolio-balances",
    path: "balances",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/balances/index.vue")
  },
  {
    name: "portfolio-history-funding-payments",
    path: "history/funding-payments",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/history/funding-payments/index.vue")
  },
  {
    name: "portfolio-history-swap",
    path: "history/swap",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/history/swap/index.vue")
  },
  {
    name: "portfolio-history-wallet",
    path: "history/wallet",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/history/wallet/index.vue")
  },
  {
    name: "portfolio",
    path: "",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/index.vue")
  },
  {
    name: "portfolio-orders-futures-grid",
    path: "orders/futures-grid",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/futures-grid.vue")
  },
  {
    name: futures9W40KNjEyEMeta?.name,
    path: "orders/futures",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/futures.vue"),
    children: [
  {
    name: "portfolio-orders-futures",
    path: "",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/futures/index.vue")
  },
  {
    name: "portfolio-orders-futures-order-history",
    path: "order-history",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/futures/order-history.vue")
  },
  {
    name: "portfolio-orders-futures-trade-history",
    path: "trade-history",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/futures/trade-history.vue")
  },
  {
    name: "portfolio-orders-futures-triggers",
    path: "triggers",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/futures/triggers.vue")
  }
]
  },
  {
    name: spot_45gridc1ZB6pm3igMeta?.name,
    path: "orders/spot-grid",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/spot-grid.vue"),
    children: [
  {
    name: "portfolio-orders-spot-grid-history",
    path: "history",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/spot-grid/history.vue")
  },
  {
    name: "portfolio-orders-spot-grid",
    path: "",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/spot-grid/index.vue")
  }
]
  },
  {
    name: spotmIGxOW6cWBMeta?.name,
    path: "orders/spot",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/spot.vue"),
    children: [
  {
    name: "portfolio-orders-spot",
    path: "",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/spot/index.vue")
  },
  {
    name: "portfolio-orders-spot-order-history",
    path: "order-history",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/spot/order-history.vue")
  },
  {
    name: "portfolio-orders-spot-trade-history",
    path: "trade-history",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/spot/trade-history.vue")
  }
]
  },
  {
    name: "portfolio-positions",
    path: "positions",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/positions/index.vue")
  },
  {
    name: authzOFwuU4U3TrMeta?.name,
    path: "settings/authz",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/settings/authz.vue"),
    children: [
  {
    name: "portfolio-settings-authz-granter",
    path: "granter",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/settings/authz/granter.vue")
  },
  {
    name: "portfolio-settings-authz",
    path: "",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/settings/authz/index.vue")
  }
]
  },
  {
    name: "portfolio-settings-autosign",
    path: "settings/autosign",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/settings/autosign.vue")
  },
  {
    name: "portfolio-settings",
    path: "settings",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/settings/index.vue")
  },
  {
    name: "portfolio-settings-preferences",
    path: "settings/preferences",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/settings/preferences.vue")
  },
  {
    name: "portfolio-subaccounts",
    path: "subaccounts",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/subaccounts/index.vue")
  }
]
  },
  {
    name: spotT07bwsa8pYMeta?.name,
    path: "/spot",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/spot.vue"),
    children: [
  {
    name: "spot-slug",
    path: ":slug()",
    meta: _91slug_93kxmdUIcQ6GMeta || {},
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/spot/[slug].vue")
  },
  {
    name: "spot",
    path: "",
    meta: indexT7HjhYUG5NMeta || {},
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/spot/index.vue")
  }
]
  },
  {
    name: "swap",
    path: "/swap",
    meta: swapjOTFhDPqakMeta || {},
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/swap.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/terms.vue")
  },
  {
    name: "trade-and-earn",
    path: "/trade-and-earn",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/trade-and-earn.vue")
  },
  {
    name: "trading-bots-liquidity-bots",
    path: "/trading-bots/liquidity-bots",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/trading-bots/liquidity-bots.vue"),
    children: [
  {
    name: "trading-bots-liquidity-bots-spot-history",
    path: "spot/history",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/trading-bots/liquidity-bots/spot/history.vue")
  },
  {
    name: "trading-bots-liquidity-bots-spot",
    path: "spot",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/trading-bots/liquidity-bots/spot/index.vue")
  }
]
  },
  {
    name: "transfer",
    path: "/transfer",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/transfer.vue")
  }
]